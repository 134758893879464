import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/entities/Banner';
import getImage from '../utils/getImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './entities-page.scss';

const OurEntities = ({ entitiesGroup }) => {
  const [accordion, setAccordion] = useState(entitiesGroup);
  const handleAccordion = (data) => {
    data.enable = !data.enable;
    setAccordion([...accordion]);
  };

  const profileInfo = (entity, index) => {
    return (
      <div className="our-donors_info_content" key={index}>
        <div>
          <a href={entity.url}>
            <div className="board_info_content_image">
              <img src={getImage(entity.img)} alt="teste"></img>
            </div>
            <p className="our-donors_info_content_title">{entity.title}</p>
          </a>
        </div>
      </div>
    );
  }

  return (
    <section className="our-donors">
      <div className="container">
        <h2 className="our-donors_title text-primary">Mês de Doar</h2>
        <p className="our-donors_text">
          Aqui no Amigos da Poli, acreditamos que a educação e os projetos de Engenharia de impacto são o caminho para desenvolvermos o nosso país, fomentando a tecnologia e criando oportunidades.
          Doar é dar esperança para cada uma das pessoas que são positivamente impactadas pelos projetos apoiados.
        </p>
        <p className="our-donors_text">
          Na campanha do Mês de Doar 2023, você tem a oportunidade de apoiar a Poli doando através da landing page de qualquer uma das entidades listadas abaixo, todas as quais estão participando de nossa campanha e ajudando o Amigos da Poli a levantar recursos.
        </p>
      </div>

      <div className="container">
        <div className="accordion">
          {entitiesGroup.map((entityGroup) => {
            return (
              <React.Fragment>
                <div className="mb-25">
                  <div
                    className="our-donors_info"
                    aria-hidden="true"
                    role="button"
                    onClick={() => handleAccordion(entityGroup)} >
                    <div className="our-donors_info_title">{entityGroup.title}</div>
                    <div className="our-donors_info_icon">
                      <FontAwesomeIcon icon={entityGroup.enable ? faAngleUp : faAngleDown} />
                    </div>
                  </div>
                  <div className="our-donors_info_accordion" id="entities">
                    {entityGroup.enable &&
                      entityGroup.entities.map((profile, i) => profileInfo(profile, i))}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="container">
        <h2 className="our-donors_title text-primary">Pontuação Geral</h2>
        <div class="container_iframe responsive-iframe-container">
          <iframe
            src="https://metabase.cloud.amigosdapoli.com.br/public/dashboard/5b0b9515-9d29-4ffe-a8f1-6897c01d54fb"
            frameborder="0"
            allowtransparency
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export const EntitiesPageTemplate = ({ entitiesGroup }) => {
  return (
    <div className="donors">
      <Banner ourNumbers={{}} />
      <OurEntities entitiesGroup={entitiesGroup} />
    </div>
  );
};

EntitiesPageTemplate.propTypes = {
  banner: PropTypes.object,
  entities: PropTypes.object,
};

const EntitiesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <EntitiesPageTemplate banner={frontmatter.banner} entitiesGroup={frontmatter.entitiesGroup} />
    </Layout>
  );
};

EntitiesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EntitiesPage;

export const entitiesPageQuery = graphql`
  query entitiesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          subtitle
        }
        entitiesGroup {
          title
          entities {
            title
            url
            img {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
