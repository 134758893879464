import React from 'react';
import PropTypes from 'prop-types';

import './Banner.scss';

const OurNumbers = () => {
  return (
    <section className="our-numbers">
      <h2 className="our-numbers_title">Nossos Números</h2>
      <p className="our-numbers_discription">Fomentamos a inovação e a educação de ponta na Poli</p>
      <div className="d-flex justify-content-center">
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">43 MM</p>
          <p className="our-numbers_info_description">em patrimônio</p>
        </div>
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">200+</p>
          <p className="our-numbers_info_description">projetos apoiados</p>
        </div>
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">100+</p>
          <p className="our-numbers_info_description">projetos associados</p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">8000+</p>
          <p className="our-numbers_info_description">doadores únicos</p>
        </div>
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">7000+</p>
          <p className="our-numbers_info_description">pessoas impactadas</p>
        </div>
      </div>
      <div className="columns cta__column" style={{ justifyContent: "center", gap: "2em" }}>
        <div className="cta__button">
          <a className="button is-link--red" href="#entities" style={{ backgroundColor: "#00ab96" }}>Doar em nome de um embaixador</a>
        </div>
        <div className="cta__button">
          <a href="https://doe.amigosdapoli.com.br" target="_black" className="button is-link--red" style={{ backgroundColor: "#00ab96" }}>
            Doar sem vínculo
          </a>
        </div>
      </div>
    </section>);
};

OurNumbers.propTypes = {
  ourNumbers: PropTypes.shape({
    donors: PropTypes.number.isRequired,
    aum: PropTypes.number.isRequired,
  }),
};

export default OurNumbers;
